.lang {
	position: absolute;
	z-index: 1005;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #f8f8f9;
	background-position: center;
	background-size: cover;
	background-image: url("../../style/assets/home/background_bright.jpg");
}

.lang-watermark {
	margin: 0 0 5% 0;
	position: relative;
	aspect-ratio: 15/8;
	width: 100%;

	transform: translateY(-10%);
}

.lang-watermarkInside {
	position: absolute;
	aspect-ratio: 738/733;
	width: 100%;

	top: 0px;
	left: 50%;

	transform: translateX(-50%);

	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/home/watermark_bright.png");
}

.lang-header {
	position: relative;
	width: 100%;
	height: 20%;
	z-index: 10;

	margin: 0 0 5% 0;

	top: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	pointer-events: none;
}

.lang-header-logo {
	width: 90%;
	height: 80%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/logoBlack2.png");
}

.lang-overflowedContainer {
	position: relative;
	width: 100%;
	height: 100%;

	overflow: hidden;
}

.lang-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.lang-overflowed::-webkit-scrollbar {
	display: none;
}

.lang-choice {
	position: relative;
	width: 80%;

	margin: 0 0 0% 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.lang-enter {
	margin: 0 0 5% 0;

	font-family: "SourceSansPro-Regular";
	color: black;
	letter-spacing: 0.12rem;
	font-size: min(20px, max(2.6vw, 2.6vh));
	line-height: min(30px, max(3.6vw, 3.6vh));
	text-align: center;
}

.lang-buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.lang-button {
	width: 70%;

	margin: 0 0 5% 0;
	padding: 4% 6% 4% 6%;

	border: 1px solid black;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "SourceSansPro-SemiBold";
	color: black;
	letter-spacing: 0.06rem;
	font-size: min(14px, max(2vw, 2vh));
	line-height: min(25px, max(3vw, 3vh));
	text-align: end;
}

.lang-logos {
	width: 80%;

	margin: 0 auto min(4vw, 2vh) auto;
	top: 100%;

	position: sticky;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.lang-logos-collab {
	font-family: "SourceSansPro-Regular";
	color: black;
	letter-spacing: 0.06rem;
	font-size: min(14px, max(2vw, 2vh));
	line-height: min(25px, max(3vw, 3vh));
	text-align: end;
}

.lang-logos-logo {
	aspect-ratio: 1/1;
	height: 80%;
	width: 32px;

	margin: 5% 8px 5% 8px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.link {
	font-family: "SourceSansPro-Regular";
	color: black;
	letter-spacing: 0.06rem;
	font-size: min(14px, max(2vw, 2vh));
	line-height: min(25px, max(3vw, 3vh));
	text-align: end;

	cursor: pointer;
}
