@font-face {
	font-family: "Museo 300";
	font-style: normal;
	font-weight: normal;
	src: local("Museo 300"),
		url("./style/fonts/Museo300-Regular.woff") format("woff");
}

@font-face {
	font-family: "Museo 700";
	font-style: normal;
	font-weight: normal;
	src: local("Museo 700"),
		url("./style/fonts/Museo700-Regular.woff") format("woff");
}

@font-face {
	font-family: "Baskerville";
	font-style: normal;
	font-weight: normal;
	src: local("Baskerville"),
		url("./style/fonts/Baskerville.ttf") format("truetype");
}

@font-face {
	font-family: "Baskerville-SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("Baskerville-SemiBold"),
		url("./style/fonts/Baskerville-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Baskerville-Bold";
	font-style: normal;
	font-weight: bold;
	src: local("Baskerville-Bold"),
		url("./style/fonts/Baskerville-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Baskerville-Italic";
	font-style: italic;
	font-weight: normal;
	src: local("Baskerville-Italic"),
		url("./style/fonts/Baskerville-Italic.ttf") format("truetype");
}

@font-face {
	font-family: "Baskerville-BoldItalic";
	font-style: italic;
	font-weight: normal;
	src: local("Baskerville-BoldItalic"),
		url("./style/fonts/Baskerville-BoldItalic.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSansPro-Bold";
	font-style: normal;
	font-weight: bold;
	src: local("SourceSansPro-Bold"),
		url("./style/fonts/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSansPro-SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("SourceSansPro-SemiBold"),
		url("./style/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSansPro-Light";
	font-style: normal;
	font-weight: normal;
	src: local("SourceSansPro-Light"),
		url("./style/fonts/SourceSansPro-ExtraLight.ttf") format("truetype");
}

@font-face {
	font-family: "SourceSansPro-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("SourceSansPro-Regular"),
		url("./style/fonts/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Palatino-Bold";
	font-style: normal;
	font-weight: bold;
	src: local("Palatino-Bold"),
		url("./style/fonts/Palatino-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Palatino-Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Palatino-Italic"),
		url("./style/fonts/Palatino-Italic.ttf") format("truetype");
}

@font-face {
	font-family: "Palatino-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Palatino-Regular"),
		url("./style/fonts/Palatino-Regular.ttf") format("truetype");
}

/*
@font-face {
  font-family: 'AiglonProWide-Light';
  font-style: normal;
  font-weight: normal;
  src: local('AiglonProWide-Light'), url('./style/fonts/AiglonProWide-Light.otf') format('opentype');
}
*/

html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.home {
	position: absolute;
	z-index: 5000;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding: 0;
}

.videoInterface {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.srt {
	position: absolute;
	top: 80%;
	left: 5%;
	width: 90%;
	height: 15%;
	/*background-color: red;*/
	z-index: 20000;
	text-align: center;
	color: white;

	pointer-events: none;
	user-select: none;
}

.srt div {
	color: white;
	width: auto;
	min-height: 1em;
	font-weight: bold;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 10px;
}

.progressbarContainer {
	position: absolute;

	width: 100%;
	height: 15%;

	bottom: 0px;
	left: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding: 0;

	pointer-events: all;

	backdrop-filter: blur(10px);

	mask: linear-gradient(
		to top,
		rgba(217, 217, 217, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}

.progressbar-content {
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;

	padding: 0;

	pointer-events: all;

	width: 90%;
}

.progressbar-contentTitle {
	color: white;

	font-family: "SourceSansPro-Regular";
	font-size: max(1px, min(4vw, 4vh, 100vw));
	text-align: left;
	line-height: max(1px, min(6vw, 4vh, 100vw));
	letter-spacing: 0.08rem;
}

.progressbar-contentTimer {
	color: white;

	font-family: "SourceSansPro-Regular";
	font-size: max(1px, min(4vw, 4vh, 100vw));
	text-align: left;
	line-height: max(1px, min(6vw, 4vh, 100vw));
	letter-spacing: 0.08rem;
}

.progressbar {
	position: absolute;

	width: 90%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding: 0;

	pointer-events: all;
}

@media screen and (min-aspect-ratio: 1/1) {
	.viewport {
		position: absolute;
		height: 99%;
		top: 0%;
		aspect-ratio: 1/1;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media screen and (min-aspect-ratio: 11/16) {
	.home {
		height: 100%;
		aspect-ratio: 11/16;
		left: 50%;
		transform: translateX(-50%);
	}

	.progressbar {
		bottom: 0%;
		aspect-ratio: 20/3;
		left: 50%;
		transform: translateX(-50%);
	}

	.progressbar-content {
		bottom: calc(0% + 32px);
		aspect-ratio: 20/3;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media screen and (min-aspect-ratio: 3/7) and (max-aspect-ratio: 11/16) {
	.home {
		height: 100%;
		width: 100%;
	}

	.progressbar {
		left: 5%;
		bottom: 0%;
		width: 90%;
	}

	.progressbar-content {
		left: 5%;
		bottom: calc(0% + 32px);
		width: 90%;
	}
}

@media screen and (max-aspect-ratio: 3/7) {
	.home {
		aspect-ratio: 3/7;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	.progressbar {
		left: 5%;
		aspect-ratio: 30/7;
		width: 90%;
		bottom: 0%;
	}

	.progressbar-content {
		bottom: calc(0% + 32px);
		left: 5%;
		aspect-ratio: 30/7;
		width: 90%;
	}
}

/*@media screen and (orientation: landscape) and (max-aspect-ratio: 6/5) {
  .home {
    aspect-ratio: 6/5;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 6/5) and (max-aspect-ratio: 7/3) {

  .home {
    height: 100%;
    width: 100%;
  }
} */

/* @media screen and (orientation: landscape) and (min-aspect-ratio: 7/3) {

  .home {
    aspect-ratio: 7/3;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  .progressbar {
    aspect-ratio: 7/30;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
} */

.clickable {
	cursor: pointer;
}

#menu {
	right: 0%;
	position: absolute;
	transition: all 0.2s linear;
}
