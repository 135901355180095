.credits {
	position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #f8f8f9;
	background-position: center;
	background-size: cover;
	background-image: url("../../style/assets/home/background_bright.jpg");

	overflow: hidden;
}

.credits-close {
	position: absolute;
	aspect-ratio: 1/1;
	width: 48px;

	right: 16px;
	top: 32px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/icons/close.svg");
}

.credits-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	margin: 0 auto 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.credits-overflowed::-webkit-scrollbar {
	display: none;
}

.credits-content {
	width: 76%;

	margin: 14vh auto 0% auto;
}

.credits-title {
	margin: 0 auto max(1px, min(4vw, 4vh)) auto;

	color: #B2934F;
	font-family: "Baskerville";
	letter-spacing: 0.05rem;
	font-size: max(1px, min(10vw, 10vh, 48px));
	line-height: max(1px, min(12vw, 12vh, 48px));
	text-align: left;
}

.credits-content-title {
	margin: max(1px, min(8vw, 3vh)) auto max(1px, min(3vw, 3vh)) auto;

	font-family: "Baskerville-BoldItalic";
	font-size: max(1px, min(4vw, 4vh, 32px));
	text-align: left;
	line-height: max(1px, min(6vw, 6vh, 48px));
	letter-spacing: 0.05rem;
}

.credits-text {
	margin: 0 auto max(1px, min(1vw, 2vh)) auto;

	font-family: "SourceSansPro-Regular";
	font-size: max(18px, min(2.6vw, 2.6vh));
	text-align: left;
	line-height: max(28px, min(3.6vw, 3.6vh));
	letter-spacing: 0.08rem;
}