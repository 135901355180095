.landingPage {
	position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #f8f8f9;
	background-position: center;
	background-size: cover;
	background-image: url("../../style/assets/home/background_bright.jpg");

	overflow: hidden;
}

.landingPage-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	margin: 0 auto 0 auto;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.landingPage-overflowed::-webkit-scrollbar {
	display: none;
}

.landingPage-title {
	width: 100%;

	margin: max(1px, min(2vw, 2vh)) auto 0 auto;

	background-position: center;
	background-repeat: no-repeat;

	color: black;

	text-align: center;

	font-family: "Baskerville";
	font-size: max(1px, min(5vw, 3vh, 32px));
	text-align: center;
	line-height: max(1px, min(6vw, 4vh, 48px));
	letter-spacing: 0.05rem;
}

.landingPage-band-cross {
	position: absolute;
	width: max(1px, min(8vw, 5vh, 32px));
	height: max(1px, min(8vw, 5vh, 32px));

	top: max(1px, min(8vw, 5vh, 32px));
	right: max(1px, min(8vw, 5vh, 32px));

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(../../style/assets/icons/close.svg);
}

.landingPage-content {
	width: 75%;

	margin: max(1px, min(10vw, 8vh)) auto max(1px, min(5vw, 4vh)) auto;

	color: black;
}

.landingPage-content-title {
	margin: 0 auto max(1px, min(5vw, 5vh)) auto;

	text-align: center;

	font-family: "SourceSansPro-Bold";
	font-size: max(1px, min(3.5vw, 2.5vh, 100vw));
	line-height: max(1px, min(6vw, 4vh, 100vw));
	letter-spacing: 0.05rem;
}

.landingPage-content-text {
	margin: 0 auto max(1px, min(3vw, 4vh)) auto;

	font-family: "SourceSansPro-Regular";
	font-size: max(18px, min(2.6vw, 2.6vh));
	text-align: center;
	line-height: max(28px, min(3.6vw, 3.6vh));
	letter-spacing: 0.08rem;
}

.landingPage-bloc {
	width: 90%;

	margin: 0 auto min(4vw, 4vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-radius: 0 0 8px 8px;

	overflow: hidden;

	color: black;
}

.link {
	color: black;
	text-decoration: none;

	font-family: "SourceSansPro-Regular";
	font-size: max(1px, min(2.5vw, 2.9vh, 32px));
	letter-spacing: 0.05rem;
	font-weight: 700;

	cursor: pointer;
}

.landingPage-share {
	margin: 16px 0 max(1px, min(8vw, 4vh, 32px)) 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.landingPage-share-icon {
	aspect-ratio: 1/1;
	height: max(1px, min(4vw, 4vh, 32px));

	margin: 0 16px max(1px, min(2vw, 1.4vh, 16px)) 16px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../style/assets/icons/share.svg);
}

.landingPage-share-text {
	margin: 0 auto max(1px, min(3vw, 4vh)) auto;

	font-family: "SourceSansPro-Regular";
	font-size: max(1px, min(3.2vw, 3vh, 100vw));
	text-align: left;
	line-height: max(1px, min(6vw, 4vh, 100vw));
	letter-spacing: 0.08rem;
}

.landingPage-logos {
	width: 80%;

	margin: 0 auto min(4vw, 2vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.landingPage-logos-logoMarly {
	aspect-ratio: 2/1;
	width: 80%;

	transform: translateY(10%);

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/logoBlack2.png");
}

.landingPage-logos-logoTimescope {
	aspect-ratio: 1/1;
	width: 64px;

	margin: 5% 8px 5% 8px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
