.intro {
	position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #f8f8f9;
	background-position: center;
	background-size: cover;
	background-image: url("../../style/assets/home/background_bright.jpg");

	overflow: hidden;
}

.intro-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.intro-header {
	position: relative;
	width: 100%;
	height: 10%;
	z-index: 10;

	margin: 0 0 0 0;

	top: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.intro-header-back {
	position: absolute;
	aspect-ratio: 1/1;
	width: 6%;

	top: 50%;
	left: 8%;

	transform: translateY(-50%);

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/backArrow.svg");
}

.intro-header-logo {
	width: 70%;
	height: 70%;

	transform: translateY(-10%);

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/logoBlack2.png");
}

.intro-ornement {
	height: 10%;
	width: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url("../../style/assets/intro/ornement.png");
}

.intro-intro {
	width: 70%;

	margin: 10% auto 10% auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.intro-title {
	margin: 0 0 4px 0;

	font-family: "Baskerville";
	color: black;
	letter-spacing: 0.08rem;
	font-size: min(24px, max(2.5vw, 2.5vh));
	line-height: min(36px, max(3vw, 3vh));
	text-align: center;
}

.intro-subtitle {
	font-family: "SourceSansPro-Regular";
	color: black;
	letter-spacing: 0.08rem;
	font-size: min(18px, max(2.6vw, 2.6vh));
	line-height: min(28px, max(3.6vw, 3.6vh));
	text-align: center;
}

sup {
	line-height: 0;
}

.intro-overflowedContainer {
	position: relative;
	width: 100%;
	height: 100%;

	overflow: hidden;
}

.intro-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.intro-overflowed::-webkit-scrollbar {
	display: none;
}

.intro-bloc {
	aspect-ratio: 6/4;
	width: 90%;

	margin: min(10vw, 10vh) auto min(10vw, 10vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	overflow: hidden;
}

.intro-bloc-header {
	width: 100%;

	margin: min(2vw, 1.5vh) auto min(1.5vw, 1vh) auto;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	font-family: "Baskerville";
	font-size: max(1px, min(5vw, 6vh, 100vw));
	line-height: max(1px, min(5vw, 6vh, 100vw));
	letter-spacing: 0.05rem;
	text-align: left;
}

.intro-bloc-headerPart2 {
	margin: max(-0.5vw, -0.5vh) 0 0 0;
	font-family: "Baskerville-Italic";
	font-size: max(1px, min(5.5vw, 6.5vh, 100vw));
	line-height: max(1px, min(5vw, 6vh, 100vw));
	letter-spacing: 0.05rem;
	text-align: left;

	color: #B2934F;
}

.intro-bloc-videoTrailerContainer {
	position: relative;
	width: 100%;
	height: 100%;

	margin: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	/* background-color: rgba(236, 230, 217, 1); Couleur de base*/
	background-color: rgba(225, 214, 189, 0.66);
}

.intro-bloc-videoTrailer {
	position: relative;
	width: calc(100% - min(8vh, 8vw));
	height: calc(100% - min(8vh, 8vw));

	margin: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.intro-bloc-videoTrailerOverlay {
	position: absolute;
	width: 100%;
	height: 100%;

	top: 0px;
	left: 0px;

	background-color: rgba(178, 147, 79, 0.7);

	mix-blend-mode: overlay;
}

.intro-bloc-videoTrailer-timer {
	position: absolute;

	top: min(2vh, 2vw);
	left: min(2vh, 2vw);

	padding: 2px 12px 2px 6px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	border: 1px solid black;
	border-radius:  max(2px, min(4vw, 3vh, 32px));

	background-color: white;

	font-family: "SourceSansPro-Regular";
	font-size: max(0px, min(3vw, 2vh, 100vw));
	text-align: left;
	line-height: max(0px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.08rem;
	color: black;
}

.intro-bloc-videoTrailer-playIcon {
	aspect-ratio: 1/1;
	height: 30%;

	margin: 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/playIcon.svg");
}

.intro-bloc-videoTrailer-newIcon {
	position: absolute;
	aspect-ratio: 1/1;
	height: 10%;

	margin: min(1vw, 1vh) 0 min(1vw, 1vh) 0;
	bottom: 0;
	left: 50%;

	transform: translateX(-50%);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 4px;

	background-color: #196aff;
}

.intro-bloc-videoTrailer-newIconText {
	margin: 0 min(4vw, 3vh) 0 min(4vw, 3vh);
	opacity: 0.9;

	font-family: "SourceSansPro";
	font-size: max(0px, min(2vw, 1.5vh, 100vw));
	text-align: center;
	line-height: max(0px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.08rem;
	color: white;
}

.intro-logos {
	width: 80%;

	margin: 0 auto min(4vw, 2vh) auto;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	gap: 4vh;
}

.intro-logos-logo {
	margin: min(6vw, 4vh) 0 min(12vw, 8vh) 0;
	aspect-ratio: 1/1;
	height: min(18vw, 10vh);

	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
}

/* OLD */
.intro-video {
	position: absolute;
	width: 100%;
	height: 100%;
}

.intro-click {
	z-index: 12000;
	position: absolute;
	width: 80%;
	height: 30%;
}
