.infoPage {
	position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #f8f8f9;
	background-position: center;
	background-size: cover;
	background-image: url("../../style/assets/home/background_bright.jpg");

	overflow: hidden;
}

.infoPage-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	margin: 0 auto 0 auto;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.infoPage-overflowed::-webkit-scrollbar {
	display: none;
}

.infoPage-intro {
	margin: max(1px, min(10vw, 6vh)) 0 max(1px, min(4vw, 4vh)) 0;
}

.infoPage-title {
	width: 100%;

	margin: 0 auto 0 auto;

	color: black;

	text-align: center;

	font-family: "Baskerville";
	font-size: max(1px, min(9vw, 6vh, 32px));
	text-align: center;
	line-height: max(1px, min(9vw, 6vh, 48px));
	letter-spacing: 0.05rem;
}

.infoPage-subtitle {
	width: 100%;

	margin: 0 auto 0 auto;

	color: #b2934f;

	text-align: center;

	font-family: "Baskerville-Italic";
	font-size: max(1px, min(11vw, 7vh, 48px));
	text-align: center;
	line-height: max(1px, min(11vw, 7vh, 48px));
	letter-spacing: 0.05rem;
}

.infoPage-afficheContainer {
	position: relative;
	aspect-ratio: 1500/1800;
	width: 80%;

	margin: 0 auto min(4vw, 4vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: rgba(225, 214, 189, 0.66);
}

.infoPage-affiche {
	width: calc(100% - min(8vh, 8vw));
	height: calc(100% - min(8vh, 8vw));

	margin: 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.infoPage-content {
	width: 80%;

	margin: max(1px, min(8vw, 8vh)) auto max(1px, min(8vw, 8vh)) auto;

	color: black;
}

.infoPage-content-title {
	margin: 0 auto max(1px, min(4vw, 4vh)) auto;

	font-family: "Baskerville-BoldItalic";
	font-size: min(18px, max(2.6vw, 2.6vh));
	text-align: left;
	line-height: min(28px, max(3.6vw, 3.6vh));
	letter-spacing: 0.05rem;
}

.infoPage-content-text {
	margin: 0 auto max(1px, min(3vw, 4vh)) auto;

	font-family: "SourceSansPro-Regular";
	font-size: min(18px, max(2.6vw, 2.6vh));
	text-align: left;
	line-height: min(28px, max(3.6vw, 3.6vh));
	letter-spacing: 0.08rem;
}

.infoPage-bloc {
	width: 90%;

	margin: 0 auto min(4vw, 4vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-radius: 0 0 8px 8px;

	overflow: hidden;

	color: black;
}

.infoPage-bloc-videoTrailer {
	position: relative;
	aspect-ratio: 1920/1080;
	width: 100%;

	margin: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	border-radius: 8px 8px 0 0;
}

.infoPage-bloc-videoTrailer-playIcon {
	aspect-ratio: 1/1;
	width: 30%;

	margin: 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/playIcon.svg");
}

.infoPage-footer {
	top: 100%;
	position: sticky;
	width: 100%;
}

.infoPage-share {
	width: 50%;

	margin: 32px auto 32px auto;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	border: 1px solid black;
}

.infoPage-share-icon {
	aspect-ratio: 1/1;
	height: max(1px, min(5vw, 5vh, 32px));

	margin: 16px 12px 16px 0;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../style/assets/icons/share.svg);
}

.infoPage-share-text {
	font-family: "SourceSansPro-SemiBold";
	color: black;
	letter-spacing: 0.06rem;
	font-size: min(14px, max(2vw, 2vh));
	line-height: min(25px, max(3vw, 3vh));
	text-align: end;
}

.infoPage-blankBottom {
	height: 15vw;
}